<template>
  <el-dialog
    title="停车场权限"
    :visible.sync="dialogVisible"
    append-to-body
    @close="close"
  >
    <div class="but-box">
      <el-button type="primary" @click="confirm">确认修改</el-button>
    </div>
    <el-row class="tableContent">
      <dt-table
        ref="tabRef"
        :tableColumnList="tableColumnList"
        :data="tableList"
        :map="listMap"
        :tableConfig="tableConfig"
        :paginationConfig="paginationConfig"
        @getList="getList"
        :tableFun="tableFun"
        :hidePagination="true"
      >
        <template slot="isFirst">
          <el-table-column type="selection"></el-table-column>
        </template>
      </dt-table>
    </el-row>
  </el-dialog>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import dtTable from "@/components/table.vue";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data() {
    // 这里存放数据
    return {
      dialogVisible: false,
      tableColumnList: [
        {
          prop: "parkId",
          label: "停车场编号",
        },
        {
          prop: "parkName",
          label: "停车场名称",
        },
        {
          prop: "parkType",
          label: "停车场类型",
        },
      ],
      //
      tableList: {
        list: [],
      },
      //
      listMap: {
        pageNum: 1,
        pageSize: 15,
        chargCode: "",
        chargName: "",
        operName: "",
      },
      tableConfig: { border: true },
      paginationConfig: { layout: "prev,pager,next,jumper", total: 0 },
      tableFun: {
        "selection-change": this.handleChange,
      },
      // 角色默认的权限列表
      authorityIdList: [],
      roleCode: "",
      massDeleteList: [],
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  // 方法集合
  methods: {
    showDialog(val, authorityIdList) {
      this.roleCode = val.userCode;
      this.authorityIdList = authorityIdList;
      this.dialogVisible = true;
      if (authorityIdList) {
        this.setTableCheck(authorityIdList);
      }
      this.getList();
    },
    close() {
      this.dialogVisible = false;
    },
    //表格多选
    handleChange(val) {
      this.massDeleteList = val;
    },
    // 获取权限
    getList() {
      let info = {
        pageNum: 1,
        pageSize: 10000000,
      };
      this.$yardManagementApi.ParkController(info).then((response) => {
        this.tableList.list = response.resultEntity.list;
        this.paginationConfig.total = response.resultEntity.total;
        this.setTableCheck(this.authorityIdList);
      });
    },
    setTableCheck(authorityIdList) {
      this.$nextTick(() => {
        this.$refs.tabRef.$children[0].clearSelection();
        for (let row of this.tableList.list) {
          for (let authorityId of authorityIdList) {
            if (authorityId.parkId == row.parkId) {
              this.$refs.tabRef.$children[0].toggleRowSelection(row, true);
              break;
            }
          }
        }
      });
    },
    // 修改确认
    confirm() {
      let parkIdList = [];
      this.massDeleteList.forEach((item) => {
        parkIdList.push(item.parkId);
      });
      this.setAuthorityOneRole(this.roleCode, parkIdList);
    },
    async setAuthorityOneRole(roleCode, checedkList) {
      let info = [
        {
          roleCode: roleCode,
        },
      ];
      await this.$systemRoleManagement
        .delRoleAuthority(info)
        .then(() => {})
        .catch(() => {
          this.$message.error("操作失败！");
        });
      if (checedkList && checedkList.length !== 0) {
        let addInfo = [];
        checedkList.forEach((item) => {
          addInfo.push({
            parkId: item,
            roleCode: roleCode,
          });
        });
        await this.$systemRoleManagement
          .insertRoleAuthority(addInfo)
          .then(() => {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.close();
          })
          .catch(() => {
            this.$message.error("操作失败！");
          });
      } else if (checedkList === null) {
        return;
      } else {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.close();
      }
    },
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.but-box {
  margin: 20px;
}
.tableContent {
  height: 500px;
  overflow: auto;
}
/*修改滚动条样式*/
.tableContent::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  /**/
}
.tableContent::-webkit-scrollbar-track {
  background: #0a2041;
  border-radius: 2px;
}
.tableContent::-webkit-scrollbar-thumb {
  background: #0065bd;
  border-radius: 10px;
}
</style>
