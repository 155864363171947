<!--
 * @Author: houjinduo
 * @Date: 2024-07-23 17:43:27
 * @LastEditors: houjinduo
 * @LastEditTime: 2024-08-01 16:31:05
 * @Description: 请填写简介
-->
<template>
  <el-dialog
    title="绑定泊位"
    :visible.sync="dialogVisible"
    append-to-body
    @close="close"
  >
    <el-form :inline="true" :model="searchForm">
      <el-row class="topFirstLine">
        <el-col :span="14">
          <el-form-item label-width="90px" label="选择停车场">
            <el-select
              v-model="searchForm.park"
              placeholder="请选择停车场"
              @change="changePark"
            >
              <el-option
                v-for="item in parkList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="confirm">确认修改</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="tableContent">
      <dt-table
        ref="tabRef"
        :tableColumnList="tableColumnList"
        :data="tableDataList"
        :map="listMap"
        :tableConfig="tableConfig"
        :paginationConfig="paginationConfig"
        @getList="getList"
        :tableFun="tableFun"
        :hidePagination="true"
      >
        <template slot="isFirst">
          <el-table-column type="selection"></el-table-column>
        </template>
      </dt-table>
    </el-row>
  </el-dialog>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import dtTable from "@/components/table.vue";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data() {
    // 这里存放数据
    return {
      dialogVisible: false,
      searchForm: {},
      parkList: [],
      tableColumnList: [
        {
          prop: "parkSpaceId",
          label: "泊位编号",
        },
        {
          prop: "parkSpaceId",
          label: "泊位号",
        },
      ],
      //数据列表
      tableDataList: {
        list: [],
      },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableConfig: { border: true },
      paginationConfig: { layout: "prev,pager,next,jumper", total: 0 },
      tableFun: {
        "selection-change": this.handleChange,
      },
      massDeleteList: [],
      peopleList: {},
      inspectionSpaceList: [],
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  // 方法集合
  methods: {
    //表格多选
    handleChange(val) {
      this.massDeleteList = val;
    },
    //分页
    getList(change) {
      this.pageSize = change.pageSize;
      this.pageNum = change.pageNum;
      this.getParkList();
    },
    // 获取所有停车场
    getParkList(authorityIdList) {
      console.log(authorityIdList, "authorityIdList");
      let info = {
        pageNum: 1,
        pageSize: 10000000,
      };
      this.$yardManagementApi.ParkController(info).then((response) => {
        const list = [];
        response.resultEntity.list.forEach((item) => {
          authorityIdList.forEach((element) => {
            if (item.parkId == element.parkId) {
              list.push({
                label: item.parkName,
                value: item.parkId,
              });
            }
          });
        });
        this.parkList = list;
      });
    },
    // 获取到巡检员绑定的所有泊位
    getInspectionSpaceLis() {
      let info = {
        userCode: this.peopleList.userCode,
      };
      this.$chargeManManagement.queryInspectionSpaceList(info).then((res) => {
        this.inspectionSpaceList = res.resultEntity;
        this.setTableCheck(this.inspectionSpaceList);
      });
    },
    // 打开弹窗
    showDialog(val, authorityIdList) {
      this.peopleList = val;
      this.dialogVisible = true;
      this.getParkList(authorityIdList);
    },
    close() {
      this.dialogVisible = false;
    },
    changePark(val) {
      let info = {
        parkId: val,
      };
      console.log(info);
      this.$realTimeVideoMonitor.queryParkList(info).then((res) => {
        this.tableDataList.list = res.resultEntity;
        this.getInspectionSpaceLis();
        this.setTableCheck(this.inspectionSpaceList);
      });
    },
    confirm() {
      let info = {
        userCode: this.peopleList.userCode,
        parkId: this.searchForm.park,
        inspectionSpaceBeanList: this.massDeleteList.map((item) => {
          return {
            userCode: this.peopleList.userCode,
            parkSpaceId: item.parkSpaceId,
            parkId: item.parkId,
          };
        }),
      };
      console.log(info, "info");
      this.$chargeManManagement
        .saveInspectionSpace(info)
        .then(() => {
          this.$message({
            message: "绑定泊位成功",
            type: "success",
          });
          this.close();
        })
        .catch(() => {
          this.$message.error("操作失败！");
        });
    },
    setTableCheck(authorityIdList) {
      this.$nextTick(() => {
        this.$refs.tabRef.$children[0].clearSelection();
        for (let row of this.tableDataList.list) {
          for (let authorityId of authorityIdList) {
            if (
              authorityId.parkId == row.parkId &&
              authorityId.parkSpaceId == row.parkSpaceId
            ) {
              this.$refs.tabRef.$children[0].toggleRowSelection(row, true);
              break;
            }
          }
        }
      });
    },
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.tableContent {
  height: 500px;
  overflow: auto;
}
/*修改滚动条样式*/
.tableContent::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  /**/
}
.tableContent::-webkit-scrollbar-track {
  background: #0a2041;
  border-radius: 2px;
}
.tableContent::-webkit-scrollbar-thumb {
  background: #0065bd;
  border-radius: 10px;
}
</style>
