<template>
  <div class="body">
    <el-row class="topSelectTwoLine">
      <el-form :inline="true" :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px" label="巡检员名称">
              <el-input
                v-model="searchForm.userName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item
              class="aformlabel"
              label="联系电话"
              label-width="90px"
            >
              <el-input placeholder="请输入" v-model="searchForm.phoneNumber">
              </el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row class="topButton">
          <el-button type="primary" @click="queryButton()">查 询</el-button>
          <el-button type="info" @click="resetForm" style="margin-left: 17px"
            >重 置</el-button
          >
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>巡检员列表</span>
        <!--<el-row class="tableTitleButton">
          <el-button type="primary" @click="batchBinding"
            >车位绑定</el-button
          >
           <el-button type="info" style="margin-left: 17px">导出</el-button> 
        </el-row>-->
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table
          :tableColumnList="tableColumnList"
          :data="tableDataList"
          :map="listMap"
          ref="tableRef"
          :paginationConfig="paginationConfig"
          :tableConfig="tableConfig"
          @getList="getList"
          :tableFun="tableFun"
        >
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="绑定权限">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleClick(scope.row, 'parking')"
                  size="medium"
                  >停车场权限</el-button
                >
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="泊位管理">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleClick(scope.row, 'berth')"
                  size="medium"
                  >绑定泊位</el-button
                >
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <power-dialog ref="powerDialogRef"></power-dialog>
    <bindBerth_dialog ref="bindBerthDialogRef"></bindBerth_dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import dtTable from "@/components/table.vue";
import powerDialog from "./components/powerDialog.vue";
import bindBerth_dialog from "./components/bindBerthDialog.vue";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
    powerDialog,
    bindBerth_dialog,
  },
  data() {
    // 这里存放数据
    return {
      searchForm: {}, //查询表单
      pageNum: 1,
      pageSize: 15,
      tableColumnList: [
        {
          prop: "userCode",
          label: "巡检员编号",
        },
        {
          prop: "userName",
          label: "巡检员名称",
        },
        {
          prop: "phoneNumber",
          label: "巡检员电话",
        },
      ],
      //数据列表
      tableDataList: {
        list: [],
      },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      //分页样式
      paginationConfig: { layout: "prev,sizes,pager,next,jumper", total: 0 },
      tableConfig: { border: true },
      tableFun: {
        "selection-change": this.handleChange,
      },
      Pager: null, //获取分页器
      authorityIdList: [],
      massDeleteList: [],
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    //获取分页器组件
    // this.Pager = this.$refs.tableRef.$children[1]
    this.initialization();
  },
  // 方法集合
  methods: {
    //表格多选
    handleChange(val) {
      this.massDeleteList = val;
    },
    //分页
    getList(change) {
      this.pageSize = change.pageSize;
      this.pageNum = change.pageNum;
      this.initialization();
    },
    //查询数据
    initialization() {
      this.searchForm["pageNum"] = this.pageNum;
      this.searchForm["pageSize"] = this.pageSize;
      this.$chargeManManagement
        .queryInspectionList(this.searchForm)
        .then((res) => {
          this.tableDataList.list = res.resultEntity.list;
          this.paginationConfig.total = res.resultEntity.total;
        });
    },
    // 查询
    queryButton() {
      console.log("查询");
      // this.Pager.internalCurrentPage = 1
      this.listMap.pageNum = 1;
      this.pageNum = 1;
      this.initialization();
    },
    // 重置
    resetForm() {
      console.log("重置");
    },
    // 绑定泊位or停车场权限
    handleClick(row, type) {
      if (row.userCode) {
        let info = {
          roleCode: row.userCode,
        };
        this.$systemRoleManagement.queryRoleAuthority(info).then((res) => {
          let authorityIdList = res.resultEntity;
          this.authorityIdList = authorityIdList;
          if (type == "berth") {
            // 绑定泊位
            this.$refs.bindBerthDialogRef.showDialog(row, this.authorityIdList);
          } else {
            // 停车场权限
            this.$refs.powerDialogRef.showDialog(row, this.authorityIdList);
          }
        });
      }
    },
    // //权限分配
    // batchBinding () {
    //   if (this.massDeleteList.length == 0) {
    //     this.$message('至少选择一个人员');
    //   } else {
    //     // this.dialogTitle = '权限分配'
    //     // this.authorityType = 1
    //     // this.isShowAuthority = true
    //     // this.authorityRoleCode = ''
    //     this.handleClick('','berth')
    //   }
    // },
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
$topSelectHeight: 135px;
.body {
  width: 100%;
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background("blockBg");
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background("blockBg");
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        display: flex;
        padding-left: 91x;
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>
